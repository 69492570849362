.divMenuBgg {
  background-image: url(http://localhost:3000/images/bg-1.jpg);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.05;
  background-size: cover;
}
span.brand-text.font-weight-light {
  font-size: initial !important;
}
a.brand-link {
  height: auto!important;
}
.sidebar.os-host.os-theme-light.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden.os-host-scrollbar-vertical-hidden.os-host-transition {
  margin-top: 4em!important;
}
.react-confirm-alert-overlay.undefined {
  z-index: 1000!important;
}