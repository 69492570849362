.banner-youflix-1 {
  padding: 52.73% 0px 0px;
  position: relative;
}

iframe#ibanner-youflix-1 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.banner-youflix {
  width: 100%;
}

@media (max-width: 360px) {
  iframe#ibanner-youflix-1 {
    transform: scale(1.2);
  }
}

@media (max-width: 375px) {
  iframe#ibanner-youflix-1 {
    transform: scale(1.2);
  }
}

@media (max-width: 390px) {
  iframe#ibanner-youflix-1 {
    transform: scale(1.2);
  }
}

@media (max-width: 442px) {

  iframe#ibanner-youflix-1 {
    transform: scale(1.2);
  }

}

@media (max-width: 1024px) {

}




transform: scale(1.2);

/* position: relative;
    top: -217px;
    left: -117px;
    width: 624px;
    height: 300px;
    overflow: hidden; */