input[type="search"] {
  height: 19px;

}

textarea#txtEmail {
  min-height: 158px;
}

div#panelActions {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
}

div#panelEmail {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
  ;
}

div#panelQuest {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
}

.card.direct-chat.direct-chat-primary.cardAddBgg {
  position: relative;
  left: 10%;
  top: 10px;
  width: 80%;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  background: none;
  color: #0012ff;
  font-weight: 700;
  font-size: 18px;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #aaa;
  background: #343b4100;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator-headers {
  max-height: 50px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 15px;
}

.container-login100-form-btn {
  padding-top: 10px;
}

.example-table-theme {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 10px;
}

.example-table-theme.tabulator {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 10px;
}

/*Theme the header*/
#example-table-theme .tabulator-header {
  background-color: #333;
  color: #fff;
}

/*Allow column header names to wrap lines*/
#example-table-theme .tabulator-header .tabulator-col,
#example-table-theme .tabulator-header .tabulator-col-row-handle {
  white-space: normal;
}

/*Color the table rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row {
  color: #fff;
  background-color: #666;
}

/*Color even rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
  background-color: #444;
}

.react-grid-HeaderCell {
  padding: 4px;
}

div#divcalcular {
  border: ridge;
  padding-left: inherit;
  margin-left: auto;
  display: block;
}

button#btnCloseQuest {
  margin-top: 10px;
}

label#labelTCalc {
  background: aliceblue;
  width: -webkit-fill-available;
  text-align: center;
}

form#formRespQuestion {
  font-size: small;
}

input#inputLucro {
  background: darkgreen;
  color: white;
  font-style: italic;
  font-family: cursive;
  text-align: center;
}

button#btnGerarProp {
  margin-top: 10px;
}

.load {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 402px;
  left: 45%;
  color: #2daabf;
  z-index: 100;
}

.card-body.locCont {
  /* flex: unset; */
  padding: 0;
}

.card-header.ui-sortable-handle.gertitle.locCont {
  background: #353b42;
}

.react-confirm-alert {
  max-width: 80%;
}

p#pmodal {
  text-align: center;
}

a.abgg:hover {
  color: #ff0000;
}

a.abgg {
  font-weight: bold;
  font-family: sans-serif;
  font-style: oblique;
}

button#btnListarProp {
  margin-top: 10px;
}

label#labelTipoG {
  color: black;
  display: contents;
}

label#labelTipoP {
  color: black;
  display: contents;
}

label#labelTipoI {
  color: black;
  display: contents;
}

input#inputVTotal {
  background: #0c69ab;
  color: white;
  font-style: italic;
  font-family: cursive;
  text-align: center;
}

input#txtRetira {
  float: left;
}

section.col-lg-12.connectedSortable.ui-sortable {
  min-height: auto;
}

option {
  margin-bottom: 5px;
}

div#divReactTabulator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

div#r1 {
  box-shadow: 0 0 10px rgb(0 0 0 / 13%), 0 4px 7px rgb(0 0 0 / 20%);
  font-size: 20px;
}

button#btnRefresh {
  margin-left: 5em;
  margin-bottom: 0.3em;
}
button#btnVendaAvulsa {
  margin-left: 5em;
  margin-bottom: 0.3em;
  background-color: rgb(10, 179, 10)!important;  
}

label#labelDualList {
  display: contents;
}

input#inputTotalCx {
  background-color: darkgreen!important;
  color: white;
  font-size: 2em;
  width: 6em;
  align-items: center;
  text-align: center;
}

button#btnPgCash {
  background-color: cadetblue;
}

button#btnPgCD {
  background-color: goldenrod;
}

button#btnPgPix {
  background-color: chartreuse;
}

button#btnPgConta {
  background-color: dimgrey;
}

button#btnPgCC {
  background-color: teal;
}

div#divbtnsFPG {
/*   margin-top: 1em;
  margin-bottom: 1em; */
}
div#SelProd {
/*   margin-left: 3em;
  margin-right: 3em; */
}
label#labelReactTabulatorCxMov {
  text-align: center;
}
img#imgloadingDualAgenda {
  width: 16em;
  left: 28em;
}
button#btnGravarPG {
  background-color: green;
}
div#groupSel1 {
  margin-left: 1em;
}