@media only screen and (max-width: 600px) {
  .content-wrapper {
    margin-top: 2.5em !important;
  }

  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light.border-bottom {
    height: 2.5em !important;
  }
}

@media only screen and (max-width: 360px) {
  span.brand-text.font-weight-light {
    font-size: initial;
  }
}

@media only screen and (max-width: 320px) {
  span.brand-text.font-weight-light {
    font-size: x-small;
  }
}


button#btnAlterarFinalizar {
  background-color: green;
  margin-right: 1em;
}

li#headerlogo {
  margin-top: 6px;
  margin-right: 0.4em;
}

span.spanobs {
  font-size: 0.7em;
  color: aquamarine;
  float: left;
}

.wrap-login100 {
  padding: 55px 25px 37px 25px !important;
  box-shadow: 6px 10px 9px #00000059;
}

span.login100-form-title.p-b-76.p-t-27 {
  padding-top: 50px !important;
  padding-bottom: 25px !important;
  text-transform: capitalize !important;
  font-size: x-large;
}

i#iconzap {
  color: green;
/*   padding: 2px 11px 0px 10px;
  font-size: 2em; */
}

label#Lzap {
  margin-left: 7px;
}

div#divdtNasc {
  margin-left: 7px;
  width: 95%;
}

div#divWhats {
  width: 98%;
  padding-left: 7px;
}
input#inputNome {
  text-transform: capitalize;
}
input#inputSobreNome {
  text-transform: capitalize;
}

span.login100-form-title.p-b-76.p-t-27 {
  color: #0200d7;
  text-shadow: 0 0 0.2em #f5dd95;
}
p.pCadOk {
  text-align: justify;
  text-shadow: 2px 1px 17px #fbfbfb;
  color: floralwhite;
}
.txt3 {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #ffffff;
  /* line-height: 1; */
}
.txt3:hover {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #76f16e;
  /* line-height: 1; */
}
form#formAddNewHC.was-validated .form-control:valid, .form-control.is-valid {
  background-position: center right calc(0.375em + 0.7875rem)!important;
}
form#formAddNewHC.was-validated .form-control:invalid, .form-control.is-invalid {
  background-position: center right calc(0.375em + 0.7875rem)!important;
}
p#pmodaltu {
  text-align: left;
}
p#pmodaltuj {
  text-align: justify;
  max-width: 22em;
}
p#pmodaltualerta {
  text-align: center;
  color: red;
/*   padding-bottom: 2em; */
  font-size: 1.1em;
}
p#pmodaltualertatitulo {
  text-align: left;
/*   color: red; */
/*   padding-bottom: 2em; */
  /* font-size: x-large; */
}
.divimgloadingProcess {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(243 243 243 / 70%);
  transition: all 0.4s;
  z-index: 5;
}

img#imgloadingProcess {
  border-radius: 4px;
  position: relative;
  width: 13em;
  max-width: 90%;
  /* background: #fff; */
  padding: 1em 2em;
}
p#plistagenda {
  text-align: center;
}
img#imgloadingbg {
  width: 110%;
  max-width: 500px;
}
p#pmesano {
  margin-top: -17px;
}
ul#iconmenubg {
  margin-left: -15px;
}
label#lmelhorEmail {
  color: floralwhite;
  padding-bottom: 5px;
  padding-left: 5px;
  font-family: sans-serif;
  font-size: 1em;
}