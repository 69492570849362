input[type="search"] {
  height: 19px;

}

textarea#txtEmail {
  min-height: 158px;
}

div#panelActions {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
}

div#panelEmail {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
  ;
}

div#panelQuest {
  margin-top: 5px;
  left: 0.1%;
  width: 99.9%;
}

.card.direct-chat.direct-chat-primary.cardAddBgg {
  position: relative;
  left: 10%;
  top: 10px;
  width: 80%;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  background: none;
  color: #0012ff;
  font-weight: 700;
  font-size: 18px;
}

.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #aaa;
  background: #343b4100;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator-headers {
  max-height: 50px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 15px;
}

.container-login100-form-btn {
  padding-top: 10px;
}

.example-table-theme {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 10px;
}

.example-table-theme.tabulator {
  background-color: #ccc;
  border: 1px solid #333;
  border-radius: 10px;
}

/*Theme the header*/
#example-table-theme .tabulator-header {
  background-color: #333;
  color: #fff;
}

/*Allow column header names to wrap lines*/
#example-table-theme .tabulator-header .tabulator-col,
#example-table-theme .tabulator-header .tabulator-col-row-handle {
  white-space: normal;
}

/*Color the table rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row {
  color: #fff;
  background-color: #666;
}

/*Color even rows*/
#example-table-theme .tabulator-tableHolder .tabulator-table .tabulator-row:nth-child(even) {
  background-color: #444;
}

.react-grid-HeaderCell {
  padding: 4px;
}

div#divcalcular {
  border: ridge;
  padding-left: inherit;
  margin-left: auto;
  display: block;
}

button#btnCloseQuest {
  margin-top: 10px;
}

label#labelTCalc {
  background: aliceblue;
  width: -webkit-fill-available;
  text-align: center;
}

form#formRespQuestion {
  font-size: small;
}

input#inputLucro {
  background: darkgreen;
  color: white;
  font-style: italic;
  font-family: cursive;
  text-align: center;
}

button#btnGerarProp {
  margin-top: 10px;
}

.load {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 402px;
  left: 45%;
  color: #2daabf;
  z-index: 100;
}

.card-body.locCont {
  /* flex: unset; */
  padding: 0;
}

.card-header.ui-sortable-handle.gertitle.locCont {
  background: #353b42;
}

.react-confirm-alert {
  max-width: 70%;
}

p#pmodal {
  text-align: center;
}

a.abgg:hover {
  color: #ff0000;
}

a.abgg {
  font-weight: bold;
  font-family: sans-serif;
  font-style: oblique;
}

button#btnListarProp {
  margin-top: 10px;
}

label#labelTipoG {
  color: black;
  display: contents;
}

label#labelTipoP {
  color: black;
  display: contents;
}

label#labelTipoI {
  color: black;
  display: contents;
}

input#inputVTotal {
  background: #0c69ab;
  color: white;
  font-style: italic;
  font-family: cursive;
  text-align: center;
}

input#txtRetira {
  float: left;
}

section.col-lg-12.connectedSortable.ui-sortable {
  min-height: auto;
}

div#cardGridBggBody {
  margin-bottom: 1em;
}

@media (max-width: 442px) {
  span#titulohome {
    font-size: 0.8em !important;
  }

  div#cardGridBggBody {
    display: none;
  }

  div#cardOcorrenciasgrid {
    display: none;
  }
  div#r1 {
    width: 19em!important;
  }
}

@media (max-width: 1024px) {

  div#cardGridBggBody {
    display: none;
  }

  div#cardOcorrenciasgrid {
    display: none;
  }
}

@media (max-width: 390px) {
  span#titulohome {
    font-size: 0.8em !important;
  }

  div#cardGridBggBody {
    display: none;
  }

  div#cardOcorrenciasgrid {
    display: none;
  }
  div#r1 {
    width: 18em!important;
  }
}

@media (max-width: 375px) {
  span#titulohome {
    font-size: 0.7em !important;
  }

  div#cardGridBggBody {
    display: none;
  }

  div#cardOcorrenciasgrid {
    display: none;
  }

  div#r1 {
    width: 17em!important;
  }
}

@media (max-width: 360px) {
  span#titulohome {
    font-size: 0.6em !important;
  }

  div#cardGridBggBody {
    display: none;
  }

  div#cardOcorrenciasgrid {
    display: none;
  }
  div#r1 {
    width: 16em!important;
  }  
}

.dataid {
  font-size: 0.4em;
  /*display: contents;*/
  display: flex;
  /* line-height: 1em!important; */
  position: absolute;
  top: 62%;
  left: 22px;
}

span.info-box-icon.bg-info {
  width: 80px !important;
}

span.info-box-icon.bg-warning {
  width: 80px !important;
}

span.info-box-icon.bg-success {
  width: 80px !important;
}

.info-box {
  min-width: 300px !important;
}

.datadivid {
  display: flex;
  position: absolute;
  top: 25%;
}

.info-box-content {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.8;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
  overflow: hidden;
}

button.bggcardbtn {
  max-width: 6em;
  padding: 3px;
  margin-right: 6px;
}

div#divbtnsAction {
  display: inline-flex;
}

i.btn.btn-success.fa.fa-whatsapp.btnmot {
  margin-left: 8px;
}

.aprovsim {
  color: green;
  font-weight: bold;
}

.aprovnao {
  color: red;
  font-weight: bold;
}

.statusDisp {
  color: #17A2B8;
  font-weight: bold;
}

.statusAnda {
  color: #dda602;
  font-weight: bold;
}

div#divDadosEntrega {
  box-shadow: 0 0 0px rgba(243, 16, 16, .1), 0 1px 3px rgba(0, 18, 90, .62) !important;
  padding: 7px;
  margin-bottom: 11px;
}

span#spanSetorVlr {
  color: darkgreen;
  font-size: 1.2em;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

input#inputFone.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.4rem #ff0000;
}

input#inputNome.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.4rem #ff0000;
}

input#inputEndEntrega.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.4rem #ff0000;
}

select#SelectSetor.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.4rem #ff0000;
}

textarea#inputPedido.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.4rem #ff0000;
}

select#SelectTempo.is-invalid {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.4rem #ff0000;
}

textarea#txtaCard {
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 14px;
  background-image: linear-gradient(#F1F1F1 50%, #F9F9F9 50%);
  background-size: 100% 49px;
  border: 1px solid #CCC;
  width: 100%;
  /* height: 400px; */
  line-height: 24px;
  /* margin: 0 auto; */
  padding: 4px 8px;
  font-weight: bold !important;
  background-position: top !important;
  background-repeat: repeat !important;
}

textarea#inputPedido {
  margin-top: 9px;
  margin-bottom: 9px;
  font-size: 14px;
  background-image: linear-gradient(#F1F1F1 50%, #F9F9F9 50%);
  background-size: 100% 59px;
  border: 1px solid #CCC;
  width: 100%;
  /* height: 400px; */
  line-height: 28px;
  /* margin: 0 auto; */
  padding: 4px 8px;
  font-weight: bold !important;
  background-position: top !important;
  background-repeat: repeat !important;
}

.react-confirm-alert {
  max-width: 90% !important;
}

button#btnDevolver {
  display: flex;
  position: absolute;
  bottom: 130px;
  left: 21px;
}